.song{
  
  &__thumbnail{
    & > img{
      width: 190px;
      border-radius: 5px;
    }
  }
  &__name{
  
  }
}