.todo-list {
	& > li {
		cursor: pointer;
	}
	& > li.completed {
		color: rgb(27, 52, 12);
		font-style: italic;
		text-decoration: line-through;
	}
}
